<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark ie-nav" [containerInside]="false"  class="navCss">
  <mdb-navbar-brand>
      <a class="logo navbar-brand" href="#"><strong>Fire Apparatus Repair</strong></a>
  </mdb-navbar-brand>
  <links>
      <ul class="navbar-nav mr-auto">
          <li class="nav-item waves-light" mdbWavesEffect>
            <a routerLink="/home" class="nav-link">Home</a>
          </li>
          <li></li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a routerLink="/services" class="nav-link">Services</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
              <a routerLink="/contact" class="nav-link">Contact</a>
          </li>
          
          <li class="nav-item waves-light" mdbWavesEffect>
              <a class="nav-link" href="https://www.facebook.com/Fire-Apparatus-Repair-536064596878493/"> <mdb-icon fab icon="facebook-f"></mdb-icon></a>
          </li>
      </ul>
  </links>
</mdb-navbar>
<!-- Main -->

<router-outlet></router-outlet>