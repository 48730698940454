<main>
    <div class="row">        
      <div class="container">
          <div class="content"><h1>Fire Apparatus Repair, Inc.</h1> 
            <h3>2740 Kuser Road<br>Hamilton, NJ 08691<br><br>Phone: 609-689-2888<br><a class="mail" href="mailto:ron@fireapparatus.com?subject=Mail from Our WebSite">Ron@fireapparatusrepair.net</a>  

            </h3></div>
      </div>
      </div>
              <div class="row">
                  <div class="column">
                    <img src="../assets/images/waterousborder.png" class="img-responsive"> 
                  </div>
                  <div class="column">
                    <img src="../assets/images/spartanborder.png" class="img-responsive">
                  </div>
                  <div class="column">
                    <img src="../assets/images/evtborder.jpg" class="img-responsive">
                  </div>
                  <div class="column">
                    <img src="../assets/images/haleborder.png" class="img-responsive">
                  </div>
      </div>
