<main>
<body>
        <div class="services">
        
            <div class="heading">
                <H1>Fire Apparatus Repair, Inc.</H1>
                <h2>Your Emergency Vehicle Repair Professionals</h2>
            </div>
            <div class="row">
                <div class="column">
                    <h3>Some of the services we provide:</h3>
                    <ul>
                        <li>Preventive maintenence</li>
                        <li>Pump Repairs / Testing</li>
                        <li>Command Vehicle Conversions</li>
                        <li>Light Towers / Repairs / Installations</li>
                        <li>Battery / Charging system / Testing </li>
                        <li>Electrical Repair / Diagnostic</li>
                        <li>Emergency Lighting Upgrades / Installation</li>
                        <li>Accident Repair</li>
                        <li>Ambulance Maintenance & Repairs</li>
                        <li>Air Conditioning Service</li>
                        <li>Welding of Steel, aluminum, Stainless</li>
                        <li>Plasma cutting</li>
                        <li>Brakes/Suspension</li>
                        <li>Equipment / Tool mounting</li>
                        <li>Flame fighter and Ziamatic seat installation</li>
                        <li>Hydraulic and air line fabrication</li>
                        <li>NFPA 1911 Pump testing at your firehouse</li>
                        <li>24-hour emergency service 7 days a week 365 days a year</li>
                    </ul>
                </div>
                <div class="column">
                    <p>Please contact our experts staff and trained service technicians for fast, professional and cost effective service.</p>
                    <br>
                    <p>We service all makes and models.<br> Pumpers, Aerials, Tankers, Tenders, Ambulances, and Command vehicles</p>
                    <br>
                    <p>Pickup and delivery available</p>
                    <br>
                    <p>Spartan Chassis Service Center</p>
                    <br>
                    <p>Waterous / Hale Pump Service</p>
                </div>
                <div class="column2" id="pic">
                    <img src="../../assets/images/truckoutside.jpg">
                </div>
            </div>
        </div>
        <div class="footer">
            <img src="../../assets/images/truckoutside2.jpg">
        </div>>
    </body>
    

</main>
