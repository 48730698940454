<main>
    <div class="contact">
        <h1>Fire Apparatus Repair, Inc.</h1>
        <h2>2740 Kuser Road<br>Hamilton, NJ 08691<br><br>Phone: 609-689-2888<br>Fax: 609-584-0405
        </h2>
        <a class="mail" href="mailto:ron@fireapparatus.com?subject=Mail from Our WebSite">Ron@fireapparatusrepair.net</a>  

        
    </div>
  </main>
